import React from "react"
import "./contact.scss"
/** MATERIAL UI */
import Container from "@material-ui/core/Container"
import { Typography } from "@material-ui/core"
/** COMPONENTS */
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { useTranslation } from "react-i18next"
import { withTrans } from "../../i18n/withTrans"

import Slide from "react-reveal/Fade"
import { Flip } from "react-reveal"
import { StaticImage } from "gatsby-plugin-image"

function ContactPage() {
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <Seo title="Contact" />
      <Container className="contact">
        {/* Customer Care */}
        <div className="customer-care">
          <Slide right>
            <div>
              <StaticImage
                src="../../assets/images/decoration/contact-decoration.png"
                className="contact-decoration"
                alt=""
              />
            </div>
          </Slide>
          <Slide bottom>
            <div className="section-title">
              <span className="title">{t(`contact.help`)}</span>
            </div>
          </Slide>
          <div className="container">
            {/* <Slide right> */}
            <StaticImage
              src="../../assets/images/picture/customer-care.svg"
              className="bg-customer-care"
              alt=""
            />
            {/* </Slide> */}
            <Slide bottom>
              <span className="sub-title">{t(`contact.help-title`)}</span>
              <div className="content-list">
                <div className="content">
                  <div className="number">
                    <span className="number-text">1</span>
                  </div>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: t(`contact.help-1`),
                    }}
                    className="description"
                  />
                </div>
                <div className="content">
                  <div className="number">
                    <span className="number-text">2</span>
                  </div>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: t(`contact.help-2`),
                    }}
                    className="description"
                  />
                </div>
                <div className="content">
                  <div className="number">
                    <span className="number-text">3</span>
                  </div>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: t(`contact.help-3`),
                    }}
                    className="description"
                  />
                </div>
              </div>
            </Slide>
          </div>
        </div>

        {/* Contact Us */}
        <div className="contact-us">
          <div className="section-title">
            <span className="title">{t(`contact.contact-title`)}</span>
          </div>
          <div className="contact-list">
            <div className="contact-list-container">
              <StaticImage src="../../assets/images/icons/e-mail.png" alt="" />
              <span className="contact-name">{t(`contact.email`)}</span>
              <div className="contact-description">
                <span>{t(`contact.email-text`)}</span>
                <b>
                  {t(`contact.borrower`)} :{" "}
                  <a className="contact-blue" href="mailto:loan@asetku.com">
                    {t(`contact.email-borrower`)}
                  </a>
                </b>
                <b>
                  {t(`contact.lender`)} :{" "}
                  <a className="contact-blue" href="mailto:cs@asetku.com">
                    {t(`contact.email-lender`)}
                  </a>
                </b>{" "}
              </div>
            </div>
            <div className="contact-list-container">
              <StaticImage
                src="../../assets/images/icons/call-center.png"
                alt=""
              />
              <span className="contact-name">{t(`contact.call-center`)}</span>
              <div className="contact-description">
                <b>
                  {t(`contact.borrower`)} :{" "}
                  <a className="contact-blue" href="tel:1500116">
                    {t(`contact.call-borrower`)}
                  </a>
                  <br />
                </b>
                {t(`contact.working-hour-borrower`)}
                <b>
                  {t(`contact.lender`)} :{" "}
                  <a className="contact-blue" href="tel:1500226">
                    {t(`contact.call-lender`)}
                  </a>{" "}
                </b>
                {t(`contact.working-hour-lender`)}
              </div>
            </div>
            <div className="contact-list-container">
              <StaticImage
                src="../../assets/images/icons/relationship-manager.png"
                alt=""
              />
              <span className="contact-name">{"Relationship Manager"}</span>
              <div className="contact-description">
                {i18n.language === "en" ? (
                  <span style={{ textAlign: "center", width: "271px" }}>
                    We are so happy to give you the best service and financial
                    solution. Don't forget to input <b>RM Code</b> on your
                    Asetku mobile app.
                  </span>
                ) : (
                  <span style={{ textAlign: "center", width: "271px" }}>
                    Kami dengan senang hati memberikan solusi finansial dan
                    layanan terbaik bagi Anda. Jangan lupa untuk menginput{" "}
                    <span style={{ fontWeight: "700" }}>kode RM</span> pada
                    aplikasi Asetku Anda.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="social-media">
          <Flip bottom>
            <a
              href="//www.facebook.com/AsetKuIndonesia/"
              target="_blank"
              className="content"
              rel="noreferrer"
            >
              <StaticImage
                src="../../assets/images/icons/contact-facebook.png"
                alt=""
              />
              <span>AsetkuIndonesia</span>
            </a>
          </Flip>
          <Flip bottom>
            <a
              href="//www.instagram.com/asetku_id/?hl=en"
              target="_blank"
              className="content"
              rel="noreferrer"
            >
              <StaticImage
                src="../../assets/images/icons/contact-instagram.png"
                alt=""
              />
              <span>asetku_id</span>
            </a>
          </Flip>
          <Flip bottom>
            <a
              href="//twitter.com/asetku_id?lang=en"
              target="_blank"
              className="content"
              rel="noreferrer"
            >
              <StaticImage
                src="../../assets/images/icons/contact-twitter.png"
                alt=""
              />
              <span>asetku_id</span>
            </a>
          </Flip>
          <Flip bottom>
            <a
              href="//www.youtube.com/asetkuindonesia"
              target="_blank"
              className="content"
              rel="noreferrer"
            >
              <StaticImage
                src="../../assets/images/icons/contact-youtube.png"
                alt=""
              />
              <span>asetkuindonesia</span>
            </a>
          </Flip>
          <Flip bottom>
            <a
              href="//www.tiktok.com/@asetku_id?lang=en"
              target="_blank"
              className="content"
              rel="noreferrer"
            >
              <StaticImage
                src="../../assets/images/icons/contact-tiktok.png"
                alt=""
              />
              <span>asetku_id</span>
            </a>
          </Flip>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.287615824576!2d106.80292451675076!3d-6.225757650380989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1117c6e1b59%3A0xfce8d9a4925a338d!2sSequis%20Tower%20Building!5e0!3m2!1sen!2sid!4v1667875012694!5m2!1sen!2sid"
          width="100%"
          height="400"
          style={{ border: "0" }}
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
      </Container>
    </Layout>
  )
}

export default withTrans(ContactPage)
